/* Automatically generated file. DO NOT edit manually. See setup.js for details. */
import React from 'react';
import { Switch } from 'react-router-dom';

import CountryPage from '../pages/country';
import RouteWithSummary from './route-with-summary';

const CountryRoutes = () => (
  <Switch>
    <RouteWithSummary exact path="/afghanistan" slug="afghanistan" render={(props) => <CountryPage slug="afghanistan" {...props} />} />
<RouteWithSummary exact path="/ala-aland-islands" slug="ala-aland-islands" render={(props) => <CountryPage slug="ala-aland-islands" {...props} />} />
<RouteWithSummary exact path="/albania" slug="albania" render={(props) => <CountryPage slug="albania" {...props} />} />
<RouteWithSummary exact path="/algeria" slug="algeria" render={(props) => <CountryPage slug="algeria" {...props} />} />
<RouteWithSummary exact path="/american-samoa" slug="american-samoa" render={(props) => <CountryPage slug="american-samoa" {...props} />} />
<RouteWithSummary exact path="/andorra" slug="andorra" render={(props) => <CountryPage slug="andorra" {...props} />} />
<RouteWithSummary exact path="/angola" slug="angola" render={(props) => <CountryPage slug="angola" {...props} />} />
<RouteWithSummary exact path="/anguilla" slug="anguilla" render={(props) => <CountryPage slug="anguilla" {...props} />} />
<RouteWithSummary exact path="/antarctica" slug="antarctica" render={(props) => <CountryPage slug="antarctica" {...props} />} />
<RouteWithSummary exact path="/antigua-and-barbuda" slug="antigua-and-barbuda" render={(props) => <CountryPage slug="antigua-and-barbuda" {...props} />} />
<RouteWithSummary exact path="/argentina" slug="argentina" render={(props) => <CountryPage slug="argentina" {...props} />} />
<RouteWithSummary exact path="/armenia" slug="armenia" render={(props) => <CountryPage slug="armenia" {...props} />} />
<RouteWithSummary exact path="/aruba" slug="aruba" render={(props) => <CountryPage slug="aruba" {...props} />} />
<RouteWithSummary exact path="/australia" slug="australia" render={(props) => <CountryPage slug="australia" {...props} />} />
<RouteWithSummary exact path="/austria" slug="austria" render={(props) => <CountryPage slug="austria" {...props} />} />
<RouteWithSummary exact path="/azerbaijan" slug="azerbaijan" render={(props) => <CountryPage slug="azerbaijan" {...props} />} />
<RouteWithSummary exact path="/bahamas" slug="bahamas" render={(props) => <CountryPage slug="bahamas" {...props} />} />
<RouteWithSummary exact path="/bahrain" slug="bahrain" render={(props) => <CountryPage slug="bahrain" {...props} />} />
<RouteWithSummary exact path="/bangladesh" slug="bangladesh" render={(props) => <CountryPage slug="bangladesh" {...props} />} />
<RouteWithSummary exact path="/barbados" slug="barbados" render={(props) => <CountryPage slug="barbados" {...props} />} />
<RouteWithSummary exact path="/belarus" slug="belarus" render={(props) => <CountryPage slug="belarus" {...props} />} />
<RouteWithSummary exact path="/belgium" slug="belgium" render={(props) => <CountryPage slug="belgium" {...props} />} />
<RouteWithSummary exact path="/belize" slug="belize" render={(props) => <CountryPage slug="belize" {...props} />} />
<RouteWithSummary exact path="/benin" slug="benin" render={(props) => <CountryPage slug="benin" {...props} />} />
<RouteWithSummary exact path="/bermuda" slug="bermuda" render={(props) => <CountryPage slug="bermuda" {...props} />} />
<RouteWithSummary exact path="/bhutan" slug="bhutan" render={(props) => <CountryPage slug="bhutan" {...props} />} />
<RouteWithSummary exact path="/bolivia" slug="bolivia" render={(props) => <CountryPage slug="bolivia" {...props} />} />
<RouteWithSummary exact path="/bosnia-and-herzegovina" slug="bosnia-and-herzegovina" render={(props) => <CountryPage slug="bosnia-and-herzegovina" {...props} />} />
<RouteWithSummary exact path="/botswana" slug="botswana" render={(props) => <CountryPage slug="botswana" {...props} />} />
<RouteWithSummary exact path="/bouvet-island" slug="bouvet-island" render={(props) => <CountryPage slug="bouvet-island" {...props} />} />
<RouteWithSummary exact path="/brazil" slug="brazil" render={(props) => <CountryPage slug="brazil" {...props} />} />
<RouteWithSummary exact path="/british-indian-ocean-territory" slug="british-indian-ocean-territory" render={(props) => <CountryPage slug="british-indian-ocean-territory" {...props} />} />
<RouteWithSummary exact path="/british-virgin-islands" slug="british-virgin-islands" render={(props) => <CountryPage slug="british-virgin-islands" {...props} />} />
<RouteWithSummary exact path="/brunei" slug="brunei" render={(props) => <CountryPage slug="brunei" {...props} />} />
<RouteWithSummary exact path="/bulgaria" slug="bulgaria" render={(props) => <CountryPage slug="bulgaria" {...props} />} />
<RouteWithSummary exact path="/burkina-faso" slug="burkina-faso" render={(props) => <CountryPage slug="burkina-faso" {...props} />} />
<RouteWithSummary exact path="/burundi" slug="burundi" render={(props) => <CountryPage slug="burundi" {...props} />} />
<RouteWithSummary exact path="/cambodia" slug="cambodia" render={(props) => <CountryPage slug="cambodia" {...props} />} />
<RouteWithSummary exact path="/cameroon" slug="cameroon" render={(props) => <CountryPage slug="cameroon" {...props} />} />
<RouteWithSummary exact path="/canada" slug="canada" render={(props) => <CountryPage slug="canada" {...props} />} />
<RouteWithSummary exact path="/cape-verde" slug="cape-verde" render={(props) => <CountryPage slug="cape-verde" {...props} />} />
<RouteWithSummary exact path="/cayman-islands" slug="cayman-islands" render={(props) => <CountryPage slug="cayman-islands" {...props} />} />
<RouteWithSummary exact path="/central-african-republic" slug="central-african-republic" render={(props) => <CountryPage slug="central-african-republic" {...props} />} />
<RouteWithSummary exact path="/chad" slug="chad" render={(props) => <CountryPage slug="chad" {...props} />} />
<RouteWithSummary exact path="/chile" slug="chile" render={(props) => <CountryPage slug="chile" {...props} />} />
<RouteWithSummary exact path="/china" slug="china" render={(props) => <CountryPage slug="china" {...props} />} />
<RouteWithSummary exact path="/christmas-island" slug="christmas-island" render={(props) => <CountryPage slug="christmas-island" {...props} />} />
<RouteWithSummary exact path="/cocos-keeling-islands" slug="cocos-keeling-islands" render={(props) => <CountryPage slug="cocos-keeling-islands" {...props} />} />
<RouteWithSummary exact path="/colombia" slug="colombia" render={(props) => <CountryPage slug="colombia" {...props} />} />
<RouteWithSummary exact path="/comoros" slug="comoros" render={(props) => <CountryPage slug="comoros" {...props} />} />
<RouteWithSummary exact path="/congo-brazzaville" slug="congo-brazzaville" render={(props) => <CountryPage slug="congo-brazzaville" {...props} />} />
<RouteWithSummary exact path="/congo-kinshasa" slug="congo-kinshasa" render={(props) => <CountryPage slug="congo-kinshasa" {...props} />} />
<RouteWithSummary exact path="/cook-islands" slug="cook-islands" render={(props) => <CountryPage slug="cook-islands" {...props} />} />
<RouteWithSummary exact path="/costa-rica" slug="costa-rica" render={(props) => <CountryPage slug="costa-rica" {...props} />} />
<RouteWithSummary exact path="/cote-divoire" slug="cote-divoire" render={(props) => <CountryPage slug="cote-divoire" {...props} />} />
<RouteWithSummary exact path="/croatia" slug="croatia" render={(props) => <CountryPage slug="croatia" {...props} />} />
<RouteWithSummary exact path="/cuba" slug="cuba" render={(props) => <CountryPage slug="cuba" {...props} />} />
<RouteWithSummary exact path="/cyprus" slug="cyprus" render={(props) => <CountryPage slug="cyprus" {...props} />} />
<RouteWithSummary exact path="/czech-republic" slug="czech-republic" render={(props) => <CountryPage slug="czech-republic" {...props} />} />
<RouteWithSummary exact path="/denmark" slug="denmark" render={(props) => <CountryPage slug="denmark" {...props} />} />
<RouteWithSummary exact path="/djibouti" slug="djibouti" render={(props) => <CountryPage slug="djibouti" {...props} />} />
<RouteWithSummary exact path="/dominica" slug="dominica" render={(props) => <CountryPage slug="dominica" {...props} />} />
<RouteWithSummary exact path="/dominican-republic" slug="dominican-republic" render={(props) => <CountryPage slug="dominican-republic" {...props} />} />
<RouteWithSummary exact path="/ecuador" slug="ecuador" render={(props) => <CountryPage slug="ecuador" {...props} />} />
<RouteWithSummary exact path="/egypt" slug="egypt" render={(props) => <CountryPage slug="egypt" {...props} />} />
<RouteWithSummary exact path="/el-salvador" slug="el-salvador" render={(props) => <CountryPage slug="el-salvador" {...props} />} />
<RouteWithSummary exact path="/equatorial-guinea" slug="equatorial-guinea" render={(props) => <CountryPage slug="equatorial-guinea" {...props} />} />
<RouteWithSummary exact path="/eritrea" slug="eritrea" render={(props) => <CountryPage slug="eritrea" {...props} />} />
<RouteWithSummary exact path="/estonia" slug="estonia" render={(props) => <CountryPage slug="estonia" {...props} />} />
<RouteWithSummary exact path="/ethiopia" slug="ethiopia" render={(props) => <CountryPage slug="ethiopia" {...props} />} />
<RouteWithSummary exact path="/falkland-islands-malvinas" slug="falkland-islands-malvinas" render={(props) => <CountryPage slug="falkland-islands-malvinas" {...props} />} />
<RouteWithSummary exact path="/faroe-islands" slug="faroe-islands" render={(props) => <CountryPage slug="faroe-islands" {...props} />} />
<RouteWithSummary exact path="/fiji" slug="fiji" render={(props) => <CountryPage slug="fiji" {...props} />} />
<RouteWithSummary exact path="/finland" slug="finland" render={(props) => <CountryPage slug="finland" {...props} />} />
<RouteWithSummary exact path="/france" slug="france" render={(props) => <CountryPage slug="france" {...props} />} />
<RouteWithSummary exact path="/french-guiana" slug="french-guiana" render={(props) => <CountryPage slug="french-guiana" {...props} />} />
<RouteWithSummary exact path="/french-polynesia" slug="french-polynesia" render={(props) => <CountryPage slug="french-polynesia" {...props} />} />
<RouteWithSummary exact path="/french-southern-territories" slug="french-southern-territories" render={(props) => <CountryPage slug="french-southern-territories" {...props} />} />
<RouteWithSummary exact path="/gabon" slug="gabon" render={(props) => <CountryPage slug="gabon" {...props} />} />
<RouteWithSummary exact path="/gambia" slug="gambia" render={(props) => <CountryPage slug="gambia" {...props} />} />
<RouteWithSummary exact path="/georgia" slug="georgia" render={(props) => <CountryPage slug="georgia" {...props} />} />
<RouteWithSummary exact path="/germany" slug="germany" render={(props) => <CountryPage slug="germany" {...props} />} />
<RouteWithSummary exact path="/ghana" slug="ghana" render={(props) => <CountryPage slug="ghana" {...props} />} />
<RouteWithSummary exact path="/gibraltar" slug="gibraltar" render={(props) => <CountryPage slug="gibraltar" {...props} />} />
<RouteWithSummary exact path="/greece" slug="greece" render={(props) => <CountryPage slug="greece" {...props} />} />
<RouteWithSummary exact path="/greenland" slug="greenland" render={(props) => <CountryPage slug="greenland" {...props} />} />
<RouteWithSummary exact path="/grenada" slug="grenada" render={(props) => <CountryPage slug="grenada" {...props} />} />
<RouteWithSummary exact path="/guadeloupe" slug="guadeloupe" render={(props) => <CountryPage slug="guadeloupe" {...props} />} />
<RouteWithSummary exact path="/guam" slug="guam" render={(props) => <CountryPage slug="guam" {...props} />} />
<RouteWithSummary exact path="/guatemala" slug="guatemala" render={(props) => <CountryPage slug="guatemala" {...props} />} />
<RouteWithSummary exact path="/guernsey" slug="guernsey" render={(props) => <CountryPage slug="guernsey" {...props} />} />
<RouteWithSummary exact path="/guinea-bissau" slug="guinea-bissau" render={(props) => <CountryPage slug="guinea-bissau" {...props} />} />
<RouteWithSummary exact path="/guinea" slug="guinea" render={(props) => <CountryPage slug="guinea" {...props} />} />
<RouteWithSummary exact path="/guyana" slug="guyana" render={(props) => <CountryPage slug="guyana" {...props} />} />
<RouteWithSummary exact path="/haiti" slug="haiti" render={(props) => <CountryPage slug="haiti" {...props} />} />
<RouteWithSummary exact path="/heard-and-mcdonald-islands" slug="heard-and-mcdonald-islands" render={(props) => <CountryPage slug="heard-and-mcdonald-islands" {...props} />} />
<RouteWithSummary exact path="/holy-see-vatican-city-state" slug="holy-see-vatican-city-state" render={(props) => <CountryPage slug="holy-see-vatican-city-state" {...props} />} />
<RouteWithSummary exact path="/honduras" slug="honduras" render={(props) => <CountryPage slug="honduras" {...props} />} />
<RouteWithSummary exact path="/hong-kong-sar-china" slug="hong-kong-sar-china" render={(props) => <CountryPage slug="hong-kong-sar-china" {...props} />} />
<RouteWithSummary exact path="/hungary" slug="hungary" render={(props) => <CountryPage slug="hungary" {...props} />} />
<RouteWithSummary exact path="/iceland" slug="iceland" render={(props) => <CountryPage slug="iceland" {...props} />} />
<RouteWithSummary exact path="/india" slug="india" render={(props) => <CountryPage slug="india" {...props} />} />
<RouteWithSummary exact path="/indonesia" slug="indonesia" render={(props) => <CountryPage slug="indonesia" {...props} />} />
<RouteWithSummary exact path="/iran" slug="iran" render={(props) => <CountryPage slug="iran" {...props} />} />
<RouteWithSummary exact path="/iraq" slug="iraq" render={(props) => <CountryPage slug="iraq" {...props} />} />
<RouteWithSummary exact path="/ireland" slug="ireland" render={(props) => <CountryPage slug="ireland" {...props} />} />
<RouteWithSummary exact path="/isle-of-man" slug="isle-of-man" render={(props) => <CountryPage slug="isle-of-man" {...props} />} />
<RouteWithSummary exact path="/israel" slug="israel" render={(props) => <CountryPage slug="israel" {...props} />} />
<RouteWithSummary exact path="/italy" slug="italy" render={(props) => <CountryPage slug="italy" {...props} />} />
<RouteWithSummary exact path="/jamaica" slug="jamaica" render={(props) => <CountryPage slug="jamaica" {...props} />} />
<RouteWithSummary exact path="/japan" slug="japan" render={(props) => <CountryPage slug="japan" {...props} />} />
<RouteWithSummary exact path="/jersey" slug="jersey" render={(props) => <CountryPage slug="jersey" {...props} />} />
<RouteWithSummary exact path="/jordan" slug="jordan" render={(props) => <CountryPage slug="jordan" {...props} />} />
<RouteWithSummary exact path="/kazakhstan" slug="kazakhstan" render={(props) => <CountryPage slug="kazakhstan" {...props} />} />
<RouteWithSummary exact path="/kenya" slug="kenya" render={(props) => <CountryPage slug="kenya" {...props} />} />
<RouteWithSummary exact path="/kiribati" slug="kiribati" render={(props) => <CountryPage slug="kiribati" {...props} />} />
<RouteWithSummary exact path="/korea-north" slug="korea-north" render={(props) => <CountryPage slug="korea-north" {...props} />} />
<RouteWithSummary exact path="/korea-south" slug="korea-south" render={(props) => <CountryPage slug="korea-south" {...props} />} />
<RouteWithSummary exact path="/kosovo" slug="kosovo" render={(props) => <CountryPage slug="kosovo" {...props} />} />
<RouteWithSummary exact path="/kuwait" slug="kuwait" render={(props) => <CountryPage slug="kuwait" {...props} />} />
<RouteWithSummary exact path="/kyrgyzstan" slug="kyrgyzstan" render={(props) => <CountryPage slug="kyrgyzstan" {...props} />} />
<RouteWithSummary exact path="/lao-pdr" slug="lao-pdr" render={(props) => <CountryPage slug="lao-pdr" {...props} />} />
<RouteWithSummary exact path="/latvia" slug="latvia" render={(props) => <CountryPage slug="latvia" {...props} />} />
<RouteWithSummary exact path="/lebanon" slug="lebanon" render={(props) => <CountryPage slug="lebanon" {...props} />} />
<RouteWithSummary exact path="/lesotho" slug="lesotho" render={(props) => <CountryPage slug="lesotho" {...props} />} />
<RouteWithSummary exact path="/liberia" slug="liberia" render={(props) => <CountryPage slug="liberia" {...props} />} />
<RouteWithSummary exact path="/libya" slug="libya" render={(props) => <CountryPage slug="libya" {...props} />} />
<RouteWithSummary exact path="/liechtenstein" slug="liechtenstein" render={(props) => <CountryPage slug="liechtenstein" {...props} />} />
<RouteWithSummary exact path="/lithuania" slug="lithuania" render={(props) => <CountryPage slug="lithuania" {...props} />} />
<RouteWithSummary exact path="/luxembourg" slug="luxembourg" render={(props) => <CountryPage slug="luxembourg" {...props} />} />
<RouteWithSummary exact path="/macao-sar-china" slug="macao-sar-china" render={(props) => <CountryPage slug="macao-sar-china" {...props} />} />
<RouteWithSummary exact path="/macedonia" slug="macedonia" render={(props) => <CountryPage slug="macedonia" {...props} />} />
<RouteWithSummary exact path="/madagascar" slug="madagascar" render={(props) => <CountryPage slug="madagascar" {...props} />} />
<RouteWithSummary exact path="/malawi" slug="malawi" render={(props) => <CountryPage slug="malawi" {...props} />} />
<RouteWithSummary exact path="/malaysia" slug="malaysia" render={(props) => <CountryPage slug="malaysia" {...props} />} />
<RouteWithSummary exact path="/maldives" slug="maldives" render={(props) => <CountryPage slug="maldives" {...props} />} />
<RouteWithSummary exact path="/mali" slug="mali" render={(props) => <CountryPage slug="mali" {...props} />} />
<RouteWithSummary exact path="/malta" slug="malta" render={(props) => <CountryPage slug="malta" {...props} />} />
<RouteWithSummary exact path="/marshall-islands" slug="marshall-islands" render={(props) => <CountryPage slug="marshall-islands" {...props} />} />
<RouteWithSummary exact path="/martinique" slug="martinique" render={(props) => <CountryPage slug="martinique" {...props} />} />
<RouteWithSummary exact path="/mauritania" slug="mauritania" render={(props) => <CountryPage slug="mauritania" {...props} />} />
<RouteWithSummary exact path="/mauritius" slug="mauritius" render={(props) => <CountryPage slug="mauritius" {...props} />} />
<RouteWithSummary exact path="/mayotte" slug="mayotte" render={(props) => <CountryPage slug="mayotte" {...props} />} />
<RouteWithSummary exact path="/mexico" slug="mexico" render={(props) => <CountryPage slug="mexico" {...props} />} />
<RouteWithSummary exact path="/micronesia" slug="micronesia" render={(props) => <CountryPage slug="micronesia" {...props} />} />
<RouteWithSummary exact path="/moldova" slug="moldova" render={(props) => <CountryPage slug="moldova" {...props} />} />
<RouteWithSummary exact path="/monaco" slug="monaco" render={(props) => <CountryPage slug="monaco" {...props} />} />
<RouteWithSummary exact path="/mongolia" slug="mongolia" render={(props) => <CountryPage slug="mongolia" {...props} />} />
<RouteWithSummary exact path="/montenegro" slug="montenegro" render={(props) => <CountryPage slug="montenegro" {...props} />} />
<RouteWithSummary exact path="/montserrat" slug="montserrat" render={(props) => <CountryPage slug="montserrat" {...props} />} />
<RouteWithSummary exact path="/morocco" slug="morocco" render={(props) => <CountryPage slug="morocco" {...props} />} />
<RouteWithSummary exact path="/mozambique" slug="mozambique" render={(props) => <CountryPage slug="mozambique" {...props} />} />
<RouteWithSummary exact path="/myanmar" slug="myanmar" render={(props) => <CountryPage slug="myanmar" {...props} />} />
<RouteWithSummary exact path="/namibia" slug="namibia" render={(props) => <CountryPage slug="namibia" {...props} />} />
<RouteWithSummary exact path="/nauru" slug="nauru" render={(props) => <CountryPage slug="nauru" {...props} />} />
<RouteWithSummary exact path="/nepal" slug="nepal" render={(props) => <CountryPage slug="nepal" {...props} />} />
<RouteWithSummary exact path="/netherlands-antilles" slug="netherlands-antilles" render={(props) => <CountryPage slug="netherlands-antilles" {...props} />} />
<RouteWithSummary exact path="/netherlands" slug="netherlands" render={(props) => <CountryPage slug="netherlands" {...props} />} />
<RouteWithSummary exact path="/new-caledonia" slug="new-caledonia" render={(props) => <CountryPage slug="new-caledonia" {...props} />} />
<RouteWithSummary exact path="/new-zealand" slug="new-zealand" render={(props) => <CountryPage slug="new-zealand" {...props} />} />
<RouteWithSummary exact path="/nicaragua" slug="nicaragua" render={(props) => <CountryPage slug="nicaragua" {...props} />} />
<RouteWithSummary exact path="/niger" slug="niger" render={(props) => <CountryPage slug="niger" {...props} />} />
<RouteWithSummary exact path="/nigeria" slug="nigeria" render={(props) => <CountryPage slug="nigeria" {...props} />} />
<RouteWithSummary exact path="/niue" slug="niue" render={(props) => <CountryPage slug="niue" {...props} />} />
<RouteWithSummary exact path="/norfolk-island" slug="norfolk-island" render={(props) => <CountryPage slug="norfolk-island" {...props} />} />
<RouteWithSummary exact path="/northern-mariana-islands" slug="northern-mariana-islands" render={(props) => <CountryPage slug="northern-mariana-islands" {...props} />} />
<RouteWithSummary exact path="/norway" slug="norway" render={(props) => <CountryPage slug="norway" {...props} />} />
<RouteWithSummary exact path="/oman" slug="oman" render={(props) => <CountryPage slug="oman" {...props} />} />
<RouteWithSummary exact path="/pakistan" slug="pakistan" render={(props) => <CountryPage slug="pakistan" {...props} />} />
<RouteWithSummary exact path="/palau" slug="palau" render={(props) => <CountryPage slug="palau" {...props} />} />
<RouteWithSummary exact path="/palestine" slug="palestine" render={(props) => <CountryPage slug="palestine" {...props} />} />
<RouteWithSummary exact path="/panama" slug="panama" render={(props) => <CountryPage slug="panama" {...props} />} />
<RouteWithSummary exact path="/papua-new-guinea" slug="papua-new-guinea" render={(props) => <CountryPage slug="papua-new-guinea" {...props} />} />
<RouteWithSummary exact path="/paraguay" slug="paraguay" render={(props) => <CountryPage slug="paraguay" {...props} />} />
<RouteWithSummary exact path="/peru" slug="peru" render={(props) => <CountryPage slug="peru" {...props} />} />
<RouteWithSummary exact path="/philippines" slug="philippines" render={(props) => <CountryPage slug="philippines" {...props} />} />
<RouteWithSummary exact path="/pitcairn" slug="pitcairn" render={(props) => <CountryPage slug="pitcairn" {...props} />} />
<RouteWithSummary exact path="/poland" slug="poland" render={(props) => <CountryPage slug="poland" {...props} />} />
<RouteWithSummary exact path="/portugal" slug="portugal" render={(props) => <CountryPage slug="portugal" {...props} />} />
<RouteWithSummary exact path="/puerto-rico" slug="puerto-rico" render={(props) => <CountryPage slug="puerto-rico" {...props} />} />
<RouteWithSummary exact path="/qatar" slug="qatar" render={(props) => <CountryPage slug="qatar" {...props} />} />
<RouteWithSummary exact path="/reunion" slug="reunion" render={(props) => <CountryPage slug="reunion" {...props} />} />
<RouteWithSummary exact path="/romania" slug="romania" render={(props) => <CountryPage slug="romania" {...props} />} />
<RouteWithSummary exact path="/russia" slug="russia" render={(props) => <CountryPage slug="russia" {...props} />} />
<RouteWithSummary exact path="/rwanda" slug="rwanda" render={(props) => <CountryPage slug="rwanda" {...props} />} />
<RouteWithSummary exact path="/saint-barthelemy" slug="saint-barthelemy" render={(props) => <CountryPage slug="saint-barthelemy" {...props} />} />
<RouteWithSummary exact path="/saint-helena" slug="saint-helena" render={(props) => <CountryPage slug="saint-helena" {...props} />} />
<RouteWithSummary exact path="/saint-kitts-and-nevis" slug="saint-kitts-and-nevis" render={(props) => <CountryPage slug="saint-kitts-and-nevis" {...props} />} />
<RouteWithSummary exact path="/saint-lucia" slug="saint-lucia" render={(props) => <CountryPage slug="saint-lucia" {...props} />} />
<RouteWithSummary exact path="/saint-martin-french-part" slug="saint-martin-french-part" render={(props) => <CountryPage slug="saint-martin-french-part" {...props} />} />
<RouteWithSummary exact path="/saint-pierre-and-miquelon" slug="saint-pierre-and-miquelon" render={(props) => <CountryPage slug="saint-pierre-and-miquelon" {...props} />} />
<RouteWithSummary exact path="/saint-vincent-and-the-grenadines" slug="saint-vincent-and-the-grenadines" render={(props) => <CountryPage slug="saint-vincent-and-the-grenadines" {...props} />} />
<RouteWithSummary exact path="/samoa" slug="samoa" render={(props) => <CountryPage slug="samoa" {...props} />} />
<RouteWithSummary exact path="/san-marino" slug="san-marino" render={(props) => <CountryPage slug="san-marino" {...props} />} />
<RouteWithSummary exact path="/sao-tome-and-principe" slug="sao-tome-and-principe" render={(props) => <CountryPage slug="sao-tome-and-principe" {...props} />} />
<RouteWithSummary exact path="/saudi-arabia" slug="saudi-arabia" render={(props) => <CountryPage slug="saudi-arabia" {...props} />} />
<RouteWithSummary exact path="/senegal" slug="senegal" render={(props) => <CountryPage slug="senegal" {...props} />} />
<RouteWithSummary exact path="/serbia" slug="serbia" render={(props) => <CountryPage slug="serbia" {...props} />} />
<RouteWithSummary exact path="/seychelles" slug="seychelles" render={(props) => <CountryPage slug="seychelles" {...props} />} />
<RouteWithSummary exact path="/sierra-leone" slug="sierra-leone" render={(props) => <CountryPage slug="sierra-leone" {...props} />} />
<RouteWithSummary exact path="/singapore" slug="singapore" render={(props) => <CountryPage slug="singapore" {...props} />} />
<RouteWithSummary exact path="/slovakia" slug="slovakia" render={(props) => <CountryPage slug="slovakia" {...props} />} />
<RouteWithSummary exact path="/slovenia" slug="slovenia" render={(props) => <CountryPage slug="slovenia" {...props} />} />
<RouteWithSummary exact path="/solomon-islands" slug="solomon-islands" render={(props) => <CountryPage slug="solomon-islands" {...props} />} />
<RouteWithSummary exact path="/somalia" slug="somalia" render={(props) => <CountryPage slug="somalia" {...props} />} />
<RouteWithSummary exact path="/south-africa" slug="south-africa" render={(props) => <CountryPage slug="south-africa" {...props} />} />
<RouteWithSummary exact path="/south-georgia-and-the-south-sandwich-islands" slug="south-georgia-and-the-south-sandwich-islands" render={(props) => <CountryPage slug="south-georgia-and-the-south-sandwich-islands" {...props} />} />
<RouteWithSummary exact path="/south-sudan" slug="south-sudan" render={(props) => <CountryPage slug="south-sudan" {...props} />} />
<RouteWithSummary exact path="/spain" slug="spain" render={(props) => <CountryPage slug="spain" {...props} />} />
<RouteWithSummary exact path="/sri-lanka" slug="sri-lanka" render={(props) => <CountryPage slug="sri-lanka" {...props} />} />
<RouteWithSummary exact path="/sudan" slug="sudan" render={(props) => <CountryPage slug="sudan" {...props} />} />
<RouteWithSummary exact path="/suriname" slug="suriname" render={(props) => <CountryPage slug="suriname" {...props} />} />
<RouteWithSummary exact path="/svalbard-and-jan-mayen-islands" slug="svalbard-and-jan-mayen-islands" render={(props) => <CountryPage slug="svalbard-and-jan-mayen-islands" {...props} />} />
<RouteWithSummary exact path="/swaziland" slug="swaziland" render={(props) => <CountryPage slug="swaziland" {...props} />} />
<RouteWithSummary exact path="/sweden" slug="sweden" render={(props) => <CountryPage slug="sweden" {...props} />} />
<RouteWithSummary exact path="/switzerland" slug="switzerland" render={(props) => <CountryPage slug="switzerland" {...props} />} />
<RouteWithSummary exact path="/syria" slug="syria" render={(props) => <CountryPage slug="syria" {...props} />} />
<RouteWithSummary exact path="/taiwan" slug="taiwan" render={(props) => <CountryPage slug="taiwan" {...props} />} />
<RouteWithSummary exact path="/tajikistan" slug="tajikistan" render={(props) => <CountryPage slug="tajikistan" {...props} />} />
<RouteWithSummary exact path="/tanzania" slug="tanzania" render={(props) => <CountryPage slug="tanzania" {...props} />} />
<RouteWithSummary exact path="/thailand" slug="thailand" render={(props) => <CountryPage slug="thailand" {...props} />} />
<RouteWithSummary exact path="/timor-leste" slug="timor-leste" render={(props) => <CountryPage slug="timor-leste" {...props} />} />
<RouteWithSummary exact path="/togo" slug="togo" render={(props) => <CountryPage slug="togo" {...props} />} />
<RouteWithSummary exact path="/tokelau" slug="tokelau" render={(props) => <CountryPage slug="tokelau" {...props} />} />
<RouteWithSummary exact path="/tonga" slug="tonga" render={(props) => <CountryPage slug="tonga" {...props} />} />
<RouteWithSummary exact path="/trinidad-and-tobago" slug="trinidad-and-tobago" render={(props) => <CountryPage slug="trinidad-and-tobago" {...props} />} />
<RouteWithSummary exact path="/tunisia" slug="tunisia" render={(props) => <CountryPage slug="tunisia" {...props} />} />
<RouteWithSummary exact path="/turkey" slug="turkey" render={(props) => <CountryPage slug="turkey" {...props} />} />
<RouteWithSummary exact path="/turkmenistan" slug="turkmenistan" render={(props) => <CountryPage slug="turkmenistan" {...props} />} />
<RouteWithSummary exact path="/turks-and-caicos-islands" slug="turks-and-caicos-islands" render={(props) => <CountryPage slug="turks-and-caicos-islands" {...props} />} />
<RouteWithSummary exact path="/tuvalu" slug="tuvalu" render={(props) => <CountryPage slug="tuvalu" {...props} />} />
<RouteWithSummary exact path="/uganda" slug="uganda" render={(props) => <CountryPage slug="uganda" {...props} />} />
<RouteWithSummary exact path="/ukraine" slug="ukraine" render={(props) => <CountryPage slug="ukraine" {...props} />} />
<RouteWithSummary exact path="/united-arab-emirates" slug="united-arab-emirates" render={(props) => <CountryPage slug="united-arab-emirates" {...props} />} />
<RouteWithSummary exact path="/united-kingdom" slug="united-kingdom" render={(props) => <CountryPage slug="united-kingdom" {...props} />} />
<RouteWithSummary exact path="/united-states" slug="united-states" render={(props) => <CountryPage slug="united-states" {...props} />} />
<RouteWithSummary exact path="/uruguay" slug="uruguay" render={(props) => <CountryPage slug="uruguay" {...props} />} />
<RouteWithSummary exact path="/us-minor-outlying-islands" slug="us-minor-outlying-islands" render={(props) => <CountryPage slug="us-minor-outlying-islands" {...props} />} />
<RouteWithSummary exact path="/uzbekistan" slug="uzbekistan" render={(props) => <CountryPage slug="uzbekistan" {...props} />} />
<RouteWithSummary exact path="/vanuatu" slug="vanuatu" render={(props) => <CountryPage slug="vanuatu" {...props} />} />
<RouteWithSummary exact path="/venezuela" slug="venezuela" render={(props) => <CountryPage slug="venezuela" {...props} />} />
<RouteWithSummary exact path="/vietnam" slug="vietnam" render={(props) => <CountryPage slug="vietnam" {...props} />} />
<RouteWithSummary exact path="/virgin-islands" slug="virgin-islands" render={(props) => <CountryPage slug="virgin-islands" {...props} />} />
<RouteWithSummary exact path="/wallis-and-futuna-islands" slug="wallis-and-futuna-islands" render={(props) => <CountryPage slug="wallis-and-futuna-islands" {...props} />} />
<RouteWithSummary exact path="/western-sahara" slug="western-sahara" render={(props) => <CountryPage slug="western-sahara" {...props} />} />
<RouteWithSummary exact path="/yemen" slug="yemen" render={(props) => <CountryPage slug="yemen" {...props} />} />
<RouteWithSummary exact path="/zambia" slug="zambia" render={(props) => <CountryPage slug="zambia" {...props} />} />
<RouteWithSummary exact path="/zimbabwe" slug="zimbabwe" render={(props) => <CountryPage slug="zimbabwe" {...props} />} />
  </Switch>
);

export default CountryRoutes;